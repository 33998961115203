import { useState } from "react";
import { Route, Routes } from "react-router-dom";
// Pages
import { LandingPage } from "./pages/Landing";

function App() {
  const [language, setLanguage] = useState<"en" | "fr" | "es">("en");
  console.log("language: ", language);

  return (
    <Routes>
      <Route index element={<LandingPage />} />

      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}

export default App;
